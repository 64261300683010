import useGraphQLRequest from './useGraphQLRequest';
import { GET_LOCATION_TYPES } from '../graphql/locationTypesQueries';
import { useQuery } from '@tanstack/react-query';

export const useLocationTypes = (): {
  loading: boolean;
  locationTypes: Array<{ id: number; label: string }>;
} => {
  const { request } = useGraphQLRequest();
  const getLocationTypes = async () => {
    const { locationTypes } = await request(GET_LOCATION_TYPES, {
      input: {
        filter: {
          active: true,
          admin_only: false,
        },
        orderBy: {
          rank: true,
        },
      },
    });
    return locationTypes;
  };
  const { isLoading: loading, data: locationTypes = [] } = useQuery(
    ['location_types'],
    getLocationTypes,
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );
  return { loading, locationTypes };
};
