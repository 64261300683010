import React from 'react';
import { Box, Form, Heading } from '@medely/ui-kit';
import { FixedFooterLayout } from '../../../../components/FixedFooterLayout/FixedFooterLayout';
import { useCurrentUser, useLocationTypes, useApplicationMutations } from '../../../../hooks';
import { WorkExperienceForm } from './WorkExperienceForm';
import useWorkExperienceInfoMutations from '../../../../hooks/useWorkExperienceInfoMutations';
import { ApplicationButtons } from '../../components/ApplicationButtons';
import { LoadingState } from '../../components/LoadingState';
import { createWorkExperienceValidation } from './workExperienceSchema';
import { useWorkExperience, ValuesProps } from './useWorkExperience';
import { graphql } from '../../../../graphql/generated';
import { useGraphQLQuery } from '../../../../hooks/useGraphQLRequest';
import { type ResultOf } from '@graphql-typed-document-node/core';

// add bedside_care
const WorkHistoryGql = graphql(`
  query GetWorkHistoryForWorkExperience($input: WorkHistoryQueryInput) {
    workHistories(input: $input) {
      id
      currently_work_here
      employment_type
      end_date
      location_type_id
      start_date
      position {
        id
      }
      wage_logs {
        hourly_rate_cents
      }
    }
  }
`);

export const WorkExperience = () => {
  const { data, isLoading: workHistoryLoading } = useGraphQLQuery<ResultOf<typeof WorkHistoryGql>>({
    operationName: 'GetWorkHistoryForWorkExperience',
    query: WorkHistoryGql,
    variables: {},
  });
  const workHistories = data?.workHistories;

  const { currentUser, isLoading: currentUserLoading } = useCurrentUser();
  const { locationTypes, loading: locationTypesLoading } = useLocationTypes();
  const { totalYearsOfExperience, positionsOptions, getInitialWorkExperienceValues } =
    useWorkExperience({ currentUser });
  const { submitWorkExperienceInfo } = useWorkExperienceInfoMutations();
  const { nextStep, prevStep, isLoading: mutationsLoading } = useApplicationMutations();

  const locationTypeOptions = locationTypes.map(({ id, label }) => ({ value: id, label }));

  const baseValuesLoading = currentUserLoading || locationTypesLoading || workHistoryLoading;

  const disableButtons = baseValuesLoading || mutationsLoading;

  const defaultValues = !!workHistories ? getInitialWorkExperienceValues(workHistories[0]) : {};

  const handleSubmit = (values: ValuesProps) => {
    const wageLogData = !!values.hourly_rate_cents
      ? {
          wage_log: {
            hourly_rate_cents: values.hourly_rate_cents,
            position_id: values.position_id,
          },
        }
      : null;
    const parsedValues = {
      source: 'application_wizard',
      work_history: {
        clinical: true,
        position_id: values.position_id,
        location_type_id: values.location_type_id,
        bedside_care: values.bedside_care,
        employment_type: values.employment_type,
        currently_work_here: values.currently_work_here,
        start_date: values.start_date,
        end_date: values.end_date,
        ...wageLogData,
      },
    };
    submitWorkExperienceInfo(parsedValues, {
      onSuccess: () => {
        nextStep();
      },
    });
  };

  return baseValuesLoading ? (
    <LoadingState />
  ) : (
    <>
      <Form
        handleSubmit={handleSubmit}
        schema={createWorkExperienceValidation(totalYearsOfExperience)}
        defaultValues={defaultValues}
      >
        <FixedFooterLayout
          footerContent={<ApplicationButtons disabled={disableButtons} backClick={prevStep} />}
        >
          <Box pb={2}>
            <Heading size="s" color="text.primary">
              Add detail for your most recent paid clinical experience in the U.S.
            </Heading>
          </Box>
          <WorkExperienceForm
            positionsOptions={positionsOptions}
            locationTypeOptions={locationTypeOptions}
            currentUser={currentUser}
          />
        </FixedFooterLayout>
      </Form>
    </>
  );
};
