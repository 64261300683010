import { Box, Button, Heading, HStack, Icon, VStack } from '@medely/ui-kit';
import { FixedFooterLayout } from '../../components/FixedFooterLayout';
import usePositionInfoMutations from '../../hooks/usePositionInfoMutations';
import React from 'react';
import { ExperienceSummaryCard } from './ExperienceSummaryCard';
import { useExperienceSummaryViewQueries } from './hooks';

export const ExperienceSummaryView = () => {
  const {
    professional,
    workHistories,
    isLoading: queryIsLoading,
  } = useExperienceSummaryViewQueries();

  const professionalPositions = professional?.professional_positions || [];

  const { nextStep, prevStep, isLoading: mutationIsLoading } = usePositionInfoMutations();

  const disableButtons = queryIsLoading || mutationIsLoading;

  const handleBackClick = () => {
    prevStep();
  };

  const handleContinueClick = () => {
    nextStep();
  };

  const canContinue = !!workHistories?.length;

  return (
    <FixedFooterLayout
      footerContent={
        <HStack>
          <Box mr={0.5} width="100%">
            <Button
              onClick={handleBackClick}
              variant="outlined"
              fullWidth
              disabled={disableButtons}
            >
              Back
            </Button>
          </Box>
          <Box ml={0.5} width="100%">
            <Button
              onClick={handleContinueClick}
              variant="contained"
              fullWidth
              disabled={disableButtons || !canContinue}
            >
              Continue
            </Button>
          </Box>
        </HStack>
      }
    >
      <VStack py={3} px={2} gap={2}>
        <Box mb={2}>
          <Heading size="s">
            Complete your clinical experience to maximize earnings (optional)
          </Heading>
        </Box>
        {professionalPositions?.map((professionalPosition) => (
          <ExperienceSummaryCard
            key={professionalPosition.position.id}
            position={professionalPosition.position}
            workHistory={workHistories?.find(
              (workHistory) => workHistory.position_id === professionalPosition.position?.id,
            )}
          />
        ))}
        <Button
          variant="text"
          fullWidth
          startIcon={
            <Icon name="plus" iconSize="18px" variant="custom" color="state.teal.primary" />
          }
          onClick={() => {
            // TD PRO-2555 - open work experience form
            console.warn('not implemented yet');
          }}
        >
          Add experience
        </Button>
      </VStack>
    </FixedFooterLayout>
  );
};
