import { IAccount } from '@medely/types';

type UseWorkExperienceProps = {
  currentUser: IAccount | undefined;
};

export type ValuesProps = {
  position_id: number;
  location_type_id: number;
  bedside_care: boolean;
  employment_type: number;
  currently_work_here: boolean;
  start_date: Date;
  end_date: Date;
  hourly_rate_cents: number;
};

export const useWorkExperience = ({ currentUser }: UseWorkExperienceProps) => {
  const calculateTotalYearsOfExperience = (): number => {
    let totalYearsOfExperience = 0;
    currentUser?.professional?.professional_positions.forEach(
      //@ts-ignore need to add years_of_experience to IPosition
      (p) => (totalYearsOfExperience += p.years_of_experience),
    );
    return totalYearsOfExperience;
  };

  const totalYearsOfExperience = calculateTotalYearsOfExperience();

  const getPositionsOptions = () => {
    const options: { value: number; label: string }[] = [];
    currentUser?.professional?.professional_positions.map((position) => {
      options.push({
        value: position.position.id,
        label: position.position.display_name,
      });
    });
    return options;
  };

  const positionsOptions = getPositionsOptions();

  // @ts-ignore
  const getInitialWorkExperienceValues = (workHistoryData) => {
    return {
      position_id: workHistoryData?.position?.id,
      // bedside_care: workHistoryData?.bedside_care,
      currently_work_here: workHistoryData?.currently_work_here,
      end_date: workHistoryData?.end_date,
      employment_type: workHistoryData?.employment_type,
      location_type_id: workHistoryData?.location_type_id,
      hourly_rate_cents: workHistoryData?.wage_logs?.hourly_rate_cents,
      start_date: workHistoryData?.start_date,
    };
  };

  return {
    totalYearsOfExperience,
    positionsOptions,
    getInitialWorkExperienceValues,
  };
};
